import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";
import * as styles from "./first-section.module.scss";
import VideoPlayer from "../elements/VideoPlayer";
import videoPosterImage from '../../images/defaultVideoPoster.jpg'

const FirstSection = ({ companyInfo, ...props }) => {
  const { market } = companyInfo;

  const videoProps = {
    autoplay: false,
    controls: true,
    fluid: true,
    poster: videoPosterImage,
    sources: [
      {
        src: market.video?.file.url,
        type: market.video?.file.contentType,
      },
    ],
  };

  return (
    <section className={cx("section", "section--secondary")} {...props}>
      <div className={cx("section__content-wrapper")}>
        <div className={cx("section__header-container")}>
          <h2 className={cx("section__title")}>{market.firstSectionTitle}</h2>
          <div className={cx("section__description")}>
            {market.firstSectionDescription.firstSectionDescription}
          </div>
        </div>
        <div className={styles["videoContainer"]}>
          <VideoPlayer {...videoProps}/>
        </div>
      </div>
    </section>
  );
};

export default FirstSection;

export const query = graphql`
  fragment FirstSection on ContentfulCorpCompany {
    market {
      firstSectionTitle
      firstSectionDescription {
        firstSectionDescription
      }
      video {
        file {
          contentType
          fileName
          url
        }
      }
    }
  }
`;
