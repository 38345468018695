import React, { useState } from "react";
import { graphql, navigate } from "gatsby";
import Seo from "../components/seo";
import HeroSection from "../components/sections/HeroSection";
import FirstSection from "../components/sections/FirstSection";
import SecondSection from "../components/sections/SecondSection";
import ThirdSection from "../components/sections/ThirdSection";
import ContactSection from "../components/sections/ContactSection";
import QrCodeSection from "../components/sections/QrCodeSection";
import MainMenu from "../components/menu/MainMenu";
import MobileMenu from "../components/menu/MobileMenu";
import Footer from "../components/Footer";
import {
  isBrowser,
  isLoggedIn,
  prepareLoginForSite,
} from "../components/services/auth";
import Modal from "react-modal";

Modal.setAppElement(`#___gatsby`);

const CorporatePage = ({ data }) => {
  const {
    id,
    slug,
    title,
    password,
    market,
    reports,
    externalRelationsStrategist,
    satelliteOffice,
    mccMembers,
    communityLeaders,
    qrCode,
  } = data.contentfulCorpCompany;

  const [isMobileMenuOpen, setMobileMenuState] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuState(!isMobileMenuOpen);
  };

  const getSectionIdString = (string) => {
    return string.replace(" ", "-").toLowerCase();
  };

  const menuItems = () => {
    const labels = [];

    if (market.video && market.firstSectionMenuLabel) {
      labels.push(market.firstSectionMenuLabel);
    }

    if (market.resources && market.secondSectionMenuLabel) {
      labels.push(market.secondSectionMenuLabel);
    }

    if (reports && market.thirdSectionMenuLabel) {
      labels.push(market.thirdSectionMenuLabel);
    }

    if (
      (externalRelationsStrategist ||
        satelliteOffice ||
        mccMembers ||
        communityLeaders) &&
      market.fourthSectionMenuLabel
    ) {
      labels.push(market.fourthSectionMenuLabel);
    }

    if (labels.length > 0) {
      return labels.map((label) => {
        return { label: label, sectionId: `#${getSectionIdString(label)}` };
      });
    }
  };

  if (!isLoggedIn(id, password)) {
    const siteData = {
      siteId: id,
      siteSlug: slug,
      password: password,
      data: {
        ...data.contentfulCorpCompany,
        ...data.contentfulCorpSeoMetadataHeaderAndFooter,
      },
    };

    prepareLoginForSite(siteData);
    isBrowser() && navigate("/login");
    return null;
  }

  return (
    <div id="page-wrapper">
      <Seo title={title} />
      <MobileMenu
        menuItems={menuItems()}
        companyInfo={data.contentfulCorpCompany}
        isOpen={isMobileMenuOpen}
        pageWrapId={"content-wrapper"}
        outerContainerId={"page-wrapper"}
        onClose={toggleMobileMenu}
      />
      <MainMenu
        menuItems={menuItems()}
        companyInfo={data.contentfulCorpCompany}
        onMobileMenuToggleClick={toggleMobileMenu}
      />
      <main id="content-wrapper">
        <HeroSection
          defaultHeroBannerData={data.contentfulCorpSeoMetadataHeaderAndFooter}
          companyData={data.contentfulCorpCompany}
          id="meeting"
        />

        {market.video && (
          <FirstSection
            companyInfo={data.contentfulCorpCompany}
            {...(market.firstSectionMenuLabel && {
              id: getSectionIdString(market.firstSectionMenuLabel),
            })}
          />
        )}

        {market.resources && (
          <SecondSection
            companyInfo={data.contentfulCorpCompany}
            {...(market.secondSectionMenuLabel && {
              id: getSectionIdString(market.secondSectionMenuLabel),
            })}
          />
        )}

        {reports && (
          <ThirdSection
            companyInfo={data.contentfulCorpCompany}
            defaultImage={
              data.contentfulCorpSeoMetadataHeaderAndFooter.defaultReportImage
            }
            {...(market.thirdSectionMenuLabel && {
              id: getSectionIdString(market.thirdSectionMenuLabel),
            })}
          />
        )}

        {(externalRelationsStrategist ||
          satelliteOffice ||
          mccMembers ||
          communityLeaders) && (
          <ContactSection
            companyInfo={data.contentfulCorpCompany}
            {...(market.fourthSectionMenuLabel && {
              id: getSectionIdString(market.fourthSectionMenuLabel),
            })}
          />
        )}

        {qrCode && (
          <QrCodeSection companyInfo={data.contentfulCorpCompany} />
        )}
      </main>
      <Footer footerData={data} menuItems={menuItems} />
    </div>
  );
};

export default CorporatePage;

export const pageQuery = graphql`
  query($id: String!, $locale: String!) {
    contentfulCorpCompany(id: { eq: $id }) {
      id
      slug
      title
      password
      node_locale
      market {
        firstSectionMenuLabel
        secondSectionMenuLabel
        thirdSectionMenuLabel
        fourthSectionMenuLabel
      }
      externalRelationsStrategist {
        ...PersonDetails
      }
      ...HeroBanner
      ...MeetingInformation
      ...MainMenu
      ...FirstSection
      ...SecondSection
      ...ThirdSection
      ...ContactsSection
      ...QrCodeSection
    }
    contentfulCorpSeoMetadataHeaderAndFooter(node_locale: { eq: $locale }) {
      id
      seoTitle
      loginLabel
      incorrectPasswordError
      node_locale
      ...DefaultHeroBanner
      ...DefaultReportImage
      ...Footer
    }
  }
`;
