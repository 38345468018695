import moment from "moment-timezone";

export const isMeetingDateValid = (meetingDateFrom, meetingDateTo) => {
  const userTimezone = moment.tz.guess();
  let startDatetime = moment.utc(meetingDateFrom).tz(userTimezone);
  let endDatetime = moment.utc(meetingDateTo).tz(userTimezone);
  let showButton = true;

  if (!startDatetime.isValid() && !endDatetime.isValid()) {
    showButton = false;
  } else {
    if (startDatetime.isValid() && !endDatetime.isValid()) {
      endDatetime = startDatetime.clone().add(1, "hours");
    }

    if (!startDatetime.isValid() && endDatetime.isValid()) {
      startDatetime = endDatetime.clone().subtract(1, "hours");
    }

    if (startDatetime.diff(moment()) < 0 && endDatetime.diff(moment()) < 0) {
      showButton = false;
    }
  }

  return showButton;
};
