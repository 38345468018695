import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "gatsby";
import * as styles from "./button.module.scss"

function Button({
  children,
  className,
  to,
  onMouseEnter,
  onMouseLeave,
  target,
  onClick,
  type,
}) {
  const correctedHref = () => {
    if (to && to.slice(0, 2) === "//") {
      return `https:${to}`;
    }
    return to;
  };
  const httpPattern = /^((http|https|ftp):\/\/)/;
  const externalLink = httpPattern.test(correctedHref());
  const buttonClass = cx(styles["button"], className);

  if (to) {
    if (externalLink) {
      return (
        <a
          className={buttonClass}
          href={to}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          target={target}
        >
          {children}
        </a>
      );
    }

    return (
      <Link
        className={buttonClass}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        target={target}
        to={to}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type={type}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  to: undefined,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
  target: undefined,
  type: "button",
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  target: PropTypes.string,
  type: PropTypes.string,
};

export default Button;
