import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";
import * as styles from "./qr-code-section.module.scss";

const QrCodeSection = ({ companyInfo, ...props }) => {
  const { market, qrCode } = companyInfo;

  const renderQrCodeInfo = () => {
    if (qrCode) {
      return (
        <div className={styles["qrCodeSection__item"]}>
          <img src={qrCode.file.url} alt="qrCode" />
        </div>
      );
    }
  };

  return (
    <section className={cx("section")} {...props}>
      <div className={cx("section__content-wrapper")}>
        {market.fifthSectionHeading && (
          <div className={cx("section__header-container")}>
            <h2 className={cx("section__title")}>
              {market.fifthSectionHeading}
            </h2>
          </div>
        )}
        <div className={styles["qrCodeSection"]}>{renderQrCodeInfo()}</div>
      </div>
    </section>
  );
};

export default QrCodeSection;

export const query = graphql`
  fragment QrCodeSection on ContentfulCorpCompany {
    market {
      fifthSectionHeading
    }
    qrCode {
      file {
        url
      }
    }
  }
`;
