import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";
import * as styles from "./third-section.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaFileDownload } from "react-icons/fa";

const ThirdSection = ({ companyInfo, defaultImage, ...props }) => {
  const { market, reports } = companyInfo;

  return (
    <section className={cx("section", "section--secondary")} {...props}>
      <div className={cx("section__content-wrapper")}>
        <div className={cx("section__header-container")}>
          <h2 className={cx("section__title")}>{market.thirdSectionTitle}</h2>
        </div>
        {reports && (
          <div className={styles["stats"]}>
            {reports.map((item) => (
              <a
                href={item.file.file.url}
                rel="noopener noreferrer"
                target="_blank"
                key={item.id}
                className={styles["stats__item"]}
              >
                <GatsbyImage
                  image={item.image ? item.image.gatsbyImageData : defaultImage.gatsbyImageData}
                  alt={item.image ? item.image.title : defaultImage.title}
                  className={styles["stats__itemImage"]} />
                <div className={styles["stats__contentWrapper"]}>
                  <div className={styles["stats__itemDescription"]}>
                    {item.title ? item.title : item.file.title}
                  </div>
                  <div className={styles["stats__itemIconWrapper"]}>
                    <FaFileDownload />
                  </div>
                </div>
              </a>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default ThirdSection;

export const query = graphql`
  fragment ThirdSection on ContentfulCorpCompany {
    market {
      thirdSectionTitle
    }
    reports {
      id
      title
      image {
        title
        gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED, width: 320, height: 200)
      }
      file {
        file {
          url
        }
        title
      }
    }
  }
  fragment DefaultReportImage on ContentfulCorpSeoMetadataHeaderAndFooter {
    defaultReportImage {
      title
      gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED, width: 320, height: 200)
    }
  }
`;
