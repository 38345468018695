import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import cx from "classnames";
import * as styles from "./main-menu.module.scss";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { GatsbyImage } from "gatsby-plugin-image";
import { useMDRTLogo } from "../../hooks/useMDRTLogo";
import { isMeetingDateValid } from "../services/meetingDateValidation";

const MainMenu = ({ menuItems, companyInfo, onMobileMenuToggleClick }) => {
  const {
    companyLogo,
    companyLogoWhite,
    meetingDateFrom,
    meetingDateTo,
  } = companyInfo;

  const primaryCompanyLogo = companyLogo ? companyLogo : null;
  const secondaryCompanyLogo = companyLogoWhite
    ? companyLogoWhite
    : primaryCompanyLogo;

  const mdrtLogoLight = useMDRTLogo().light.childImageSharp;
  const mdrtLogoDark = useMDRTLogo().dark.childImageSharp;

  const [mdrtLogo, setMDRTLogo] = useState(mdrtLogoLight);
  const [correctCompanyLogo, setCorrectCompanyLogo] = useState(
    primaryCompanyLogo
  );

  const [hasScrolled, setScroll] = useState(false);

  const isCalendarButtonVisible = isMeetingDateValid(
    meetingDateFrom,
    meetingDateTo
  );

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.pageYOffset > 30);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    setMDRTLogo(hasScrolled ? mdrtLogoDark : mdrtLogoLight);
    setCorrectCompanyLogo(
      hasScrolled ? primaryCompanyLogo : secondaryCompanyLogo
    );

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    hasScrolled,
    mdrtLogoLight,
    mdrtLogoDark,
    primaryCompanyLogo,
    secondaryCompanyLogo,
  ]);

  return (
    <nav
      className={cx(styles["header"], {
        [styles["headerSecondary"]]: hasScrolled,
      })}
    >
      <div className={styles["header__logoContainer"]}>
        <a
          href="https://www.mdrt.org/"
          rel="noopener noreferrer"
          target="_blank"
          className={styles["header__logoContainerItem"]}
        >
          <GatsbyImage
            image={mdrtLogo.default}
            style={{display: "block"}}
            imgStyle={{ objectFit: "contain" }}
            alt={"mdrt"} />
        </a>
        {companyLogo && (
          <GatsbyImage
            image={correctCompanyLogo.default}
            className={styles["header__logoContainerItem"]}
            alt={companyLogo.title} />
        )}
      </div>
      <ul
        className={cx(styles["primaryMenu"], {
          [styles["primaryMenuSecondary"]]: hasScrolled,
        })}
      >
        {menuItems.map((item, id) => (
          <li className={styles["primaryMenu__item"]} key={`menu-item-${id}`}>
            <a className={styles["primaryMenu__link"]} href={item.sectionId}>
              {item.label}
            </a>
          </li>
        ))}
        {isCalendarButtonVisible && (
          <li
            className={cx(
              styles["primaryMenu__item"],
              styles["primaryMenu__itemIcon"]
            )}
          >
            <a className={styles["primaryMenu__iconButton"]} href="#meeting">
              <FaRegCalendarAlt />
            </a>
          </li>
        )}
        <li
          className={cx(
            styles["primaryMenu__item"],
            styles["primaryMenu__itemIcon"],
            styles["primaryMenu__itemMobileMenu"]
          )}
        >
          <button
            type="button"
            onClick={onMobileMenuToggleClick}
            className={styles["primaryMenu__iconButton"]}
          >
            <FiMenu />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default MainMenu;

export const query = graphql`
  fragment MainMenu on ContentfulCorpCompany {
    companyLogo {
      title
      small: gatsbyImageData(layout: FIXED, quality: 100, height: 20, placeholder: BLURRED, formats: [AUTO, WEBP])
      default: gatsbyImageData(layout: FIXED, quality: 100, height: 24, placeholder: BLURRED, formats: [AUTO, WEBP])
      gatsbyImageData(layout: FULL_WIDTH, quality: 100, width: 320, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
    companyLogoWhite {
      title
      small: gatsbyImageData(layout: FIXED, quality: 100, height: 20, placeholder: BLURRED, formats: [AUTO, WEBP])
      default: gatsbyImageData(layout: FIXED, quality: 100, height: 24, placeholder: BLURRED, formats: [AUTO, WEBP])
      gatsbyImageData(layout: FULL_WIDTH, quality: 100, width: 320, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
  }
`;
