// extracted by mini-css-extract-plugin
export var header = "main-menu-module--header--kLPtw";
export var headerSecondary = "main-menu-module--header--secondary--MNVQg";
export var header__logoContainer = "main-menu-module--header__logo-container--DAQw8";
export var header__logoContainerItem = "main-menu-module--header__logo-container-item--pkp+u";
export var primaryMenu = "main-menu-module--primary-menu--CWbRC";
export var primaryMenuSecondary = "main-menu-module--primary-menu--secondary--3esEc";
export var primaryMenu__iconButton = "main-menu-module--primary-menu__icon-button--NHujK";
export var primaryMenu__item = "main-menu-module--primary-menu__item--3jXui";
export var primaryMenu__itemIcon = "main-menu-module--primary-menu__item--icon--aIIQB";
export var primaryMenu__itemMobileMenu = "main-menu-module--primary-menu__item--mobile-menu--UDbGh";
export var primaryMenu__link = "main-menu-module--primary-menu__link--mtvhb";