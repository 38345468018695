import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";
import * as styles from "./contact-section.module.scss";
import PersonCard from "../elements/PersonCard";

const ContactsSection = ({ companyInfo, ...props }) => {
  const {
    market,
    externalRelationsStrategist,
    satelliteOffice,
    mccMembers,
    communityLeaders,
  } = companyInfo;

  const renderExternalRelationsStrategistInfo = () => {
    if (externalRelationsStrategist !== null) {
      return (
        <div className={styles["contactGroup"]}>
          <h3 className={styles["contactGroup__title"]}>
            {market.headquartersSubheading}
          </h3>
          <div className={styles["contactGroup__itemsList"]}>
            <PersonCard
              className={styles["contactGroup__item"]}
              personData={externalRelationsStrategist}
            />
          </div>
        </div>
      );
    }
  };

  const renderSatelliteOfficeInfo = () => {
    if (satelliteOffice !== null) {
      return (
        <div className={styles["contactGroup"]}>
          <h3 className={styles["contactGroup__title"]}>
            {market.satelliteOfficeSubheading}
          </h3>
          <div className={styles["contactGroup__itemsList"]}>
            <PersonCard
              className={styles["contactGroup__item"]}
              personData={satelliteOffice}
            />
          </div>
        </div>
      );
    }
  };

  const renderMccMembersInfo = () => {
    if (mccMembers !== null) {
      return (
        <div className={styles["contactGroup"]}>
          <h3 className={styles["contactGroup__title"]}>
            {market.mdrtMemberLeadersSubheading}
          </h3>
          <div className={styles["contactGroup__itemsList"]}>
            {mccMembers.map((member) => {
              return (
                <PersonCard
                  className={styles["contactGroup__item"]}
                  personData={member}
                  key={member.id}
                />
              );
            })}
          </div>
        </div>
      );
    }
  };

  const renderCommunityLeadersInfo = () => {
    if (communityLeaders !== null) {
      return (
        <div className={styles["contactGroup"]}>
          <h3 className={styles["contactGroup__title"]}>
            {market.communityLeadersSubheading}
          </h3>
          <div className={styles["contactGroup__itemsList"]}>
            {communityLeaders.map((member) => {
              return (
                <PersonCard
                  className={styles["contactGroup__item"]}
                  personData={member}
                  key={member.id}
                />
              );
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <section className={cx("section", "section--secondary")} {...props}>
      <div className={cx("section__content-wrapper")}>
        <div
          className={cx(
            "section__header-container",
            styles["contactSectionHeader"]
          )}
        >
          <h2 className={cx("section__title")}>
            {market.fourthSectionHeading}
          </h2>
        </div>
        <div>
          {renderExternalRelationsStrategistInfo()}
          {renderSatelliteOfficeInfo()}
          {renderMccMembersInfo()}
          {renderCommunityLeadersInfo()}
        </div>
      </div>
    </section>
  );
};

export default ContactsSection;

export const personDetails = graphql`
  fragment PersonDetails on ContentfulCorpPerson {
    id
    email
    name
    phone
    position
    linkedIn
    headshot {
      title
      gatsbyImageData(
        layout: CONSTRAINED
        quality: 100
        height: 400
        width: 320
        placeholder: BLURRED
        resizingBehavior: THUMB
        cropFocus: FACE
      )
    }
  }
`;

export const query = graphql`
  fragment ContactsSection on ContentfulCorpCompany {
    market {
      fourthSectionHeading
      headquartersSubheading
      satelliteOfficeSubheading
      communityLeadersSubheading
      mdrtMemberLeadersSubheading
    }
    externalRelationsStrategist {
      ...PersonDetails
    }
    satelliteOffice {
      id
      email
      name
      phone
      headshot {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
          height: 400
          width: 320
          placeholder: BLURRED
          resizingBehavior: THUMB
        )
      }
    }
    mccMembers {
      ...PersonDetails
    }
    communityLeaders {
      ...PersonDetails
    }
  }
`;
