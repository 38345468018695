import React from "react";
import * as styles from "./mobile-menu.module.scss";
import { slide as Menu } from "react-burger-menu";
import { IoIosCloseCircleOutline } from "react-icons/io";

const MobileMenu = ({ companyInfo, menuItems, onClose, ...props }) => {
  const bmStyles = {
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      zIndex: 1002,
    },
  };

  return (
    <Menu
      htmlClassName="mobile-menu-active"
      crossButtonClassName={styles["mobileMenu__closeButton"]}
      burgerButtonClassName={styles["mobileMenu__burgerButton"]}
      menuClassName={styles["mobileMenu__container"]}
      itemListClassName={styles["mobileMenu__itemsList"]}
      customCrossIcon={<IoIosCloseCircleOutline />}
      styles={bmStyles}
      onClose={onClose}
      {...props}
    >
      {menuItems.map((item, id) => (
        <a
          onClick={onClose}
          href={item.sectionId}
          className={styles["mobileMenu__item"]}
          key={`mobile-menu-item-${id}`}
        >
          {item.label}
        </a>
      ))}
    </Menu>
  );
};

export default MobileMenu;
