import React from "react";
import { graphql } from "gatsby";
import Button from "../elements/Button";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";
import moment from "moment-timezone";
import * as styles from "./schedule-meeting.module.scss";

const Dropdown = ({ children }) => {
  return (
    <div className={styles["scheduleMeetingDropdown__itemsContainer"]}>
      {children}
    </div>
  );
};

const ScheduleMeetingButton = ({ ctaText, companyData }) => {
  const {
    meetingDateFrom,
    meetingDateTo,
    meetingLink,
    meetingTopic,
    meetingInviteText,
  } = companyData;

  let startDatetime = moment.utc(meetingDateFrom);
  let endDatetime = moment.utc(meetingDateTo);

  const duration = moment.duration(endDatetime.diff(startDatetime)).asHours();

  const meetingDetails = {
    description: meetingInviteText.meetingInviteText,
    duration,
    location: meetingLink,
    title: meetingTopic,
    endDatetime: endDatetime.format("YYYYMMDDTHHmmssZ"),
    startDatetime: startDatetime.format("YYYYMMDDTHHmmssZ"),
  };

  const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);

  return (
    <AddToCalendarDropdown
      className={styles["scheduleMeetingDropdown__container"]}
      buttonText={ctaText}
      linkProps={{
        className: styles["scheduleMeetingDropdown__item"],
      }}
      event={meetingDetails}
      items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL, SHARE_SITES.OUTLOOK]}
    />
  );
};

export default ScheduleMeetingButton;

export const query = graphql`
  fragment MeetingInformation on ContentfulCorpCompany {
    meetingDateFrom
    meetingDateTo
    meetingInviteText {
      meetingInviteText
    }
    meetingLink
    meetingTopic
  }
`;
