import {
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaTwitter,
} from "react-icons/fa";
import { AiFillWechat } from "react-icons/ai";
import React from "react";
import cx from "classnames";
import * as styles from "./footer.module.scss";
import { graphql } from "gatsby";

const Footer = ({ footerData }) => {
  const {
    copyright,
    facebook,
    twitter,
    pinterest,
    instagram,
    weChat,
  } = footerData.contentfulCorpSeoMetadataHeaderAndFooter;

  return (
    <footer>
      <div
        className={cx(
          styles["footer__section"],
          styles["footer__sectionSecondary"]
        )}
      >
        <div className={styles["footer__contentWrapper"]}>
          <div>{copyright}</div>
          <div className={styles["footer__iconsWrapper"]}>
            <a
              className={styles["footer__iconLink"]}
              href={facebook}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaFacebookF className={styles["footer__icon"]} />
            </a>
            <a
              className={styles["footer__iconLink"]}
              href={twitter}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaTwitter className={styles["footer__icon"]} />
            </a>
            <a
              className={styles["footer__iconLink"]}
              href={pinterest}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaPinterestP className={styles["footer__icon"]} />
            </a>
            <a
              className={styles["footer__iconLink"]}
              href={instagram}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaInstagram className={styles["footer__icon"]} />
            </a>
            {weChat && (
              <a
                className={styles["footer__iconLink"]}
                href={weChat}
                rel="noopener noreferrer"
                target="_blank"
              >
                <AiFillWechat className={styles["footer__icon"]} />
              </a>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

export const query = graphql`
  fragment Footer on ContentfulCorpSeoMetadataHeaderAndFooter {
    copyright
    facebook
    instagram
    pinterest
    twitter
    weChat
  }
`;
