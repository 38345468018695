import React from "react";
import { graphql } from "gatsby";
import Masonry from "react-masonry-css";
import cx from "classnames";
import * as styles from "./second-section.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import VideoPlayer from "../elements/VideoPlayer";
import { IoMdClose } from "react-icons/io";
import videoPosterImage from "../../images/defaultVideoPoster.jpg";

const SecondSection = ({ companyInfo, ...props }) => {
  const { market } = companyInfo;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const masonryGridItems = market.resources.map((item) => {
    const videoProps = {
      autoplay: false,
      controls: true,
      fluid: true,
      poster: videoPosterImage,
      sources: [
        {
          src: item.attachment?.file?.url,
          type: item.attachment?.file?.contentType,
        },
      ],
    };

    let ctaItem = (item) => {
      if (!item.linkTitle && (!item.linkUrl || !item.attachment)) {
        return "";
      }

      if (item.attachment && item.isVideoModal) {
        return (
          <>
            <button
              type="button"
              className={styles["masonryGrid__itemCta"]}
              onClick={openModal}
            >
              {item.linkTitle}
            </button>
            <Modal
              isOpen={modalIsOpen}
              contentLabel={item.title}
              onRequestClose={closeModal}
              className="ReactModal__Content"
              htmlOpenClassName="ReactModal__Html--open"
            >
              <button
                className="ReactModal__Close-button"
                type="button"
                onClick={closeModal}
              >
                <IoMdClose />
              </button>
              <VideoPlayer {...videoProps} />
            </Modal>
          </>
        );
      }

      if (item.linkUrl || item.attachment) {
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={item.linkUrl ? item.linkUrl : item.attachment.file.url}
            rel={item.linkUrl ? "noopener noreferrer" : null}
            target="_blank"
            key={item.id}
            className={styles["masonryGrid__itemCta"]}
          >
            {item.linkTitle}
          </a>
        );
      }
    };

    return (
      <div className={styles["masonryGrid__item"]} key={item.id}>
        {item.image && <GatsbyImage image={item.image.gatsbyImageData} alt={item.title} />}
        <div className={styles["masonryGrid__itemContent"]}>
          <h3 className={styles["masonryGrid__itemTitle"]}>{item.title}</h3>
          {item.description && (
            <div className={styles["masonryGrid__itemDescription"]}>
              {item.description.description}
            </div>
          )}
          {ctaItem(item)}
        </div>
      </div>
    );
  });

  return (
    <section className={cx("section")} {...props}>
      <div className={cx("section__content-wrapper")}>
        <div className={cx("section__header-container")}>
          <h2 className={cx("section__title")}>{market.secondSectionTitle}</h2>
          <div className={cx("section__description")}>
            {market.secondSectionDescription.secondSectionDescription}
          </div>
        </div>
        <Masonry
          breakpointCols={{ default: 3, 1024: 2, 600: 1 }}
          className={styles["masonryGrid"]}
          columnClassName={styles["masonryGrid__column"]}
        >
          {masonryGridItems}
        </Masonry>
      </div>
    </section>
  );
};

export default SecondSection;

export const query = graphql`
  fragment SecondSection on ContentfulCorpCompany {
    market {
      secondSectionTitle
      secondSectionDescription {
        secondSectionDescription
      }
      resources {
        id
        title
        linkTitle
        linkUrl
        description {
          description
        }
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            breakpoints: [800]
          )
        }
        attachment {
          file {
            url
            fileName
            contentType
          }
          description
        }
        isVideoModal
      }
    }
  }
`;
