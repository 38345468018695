import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "@videojs/themes/dist/sea/index.css";
import cx from "classnames";

class VideoPlayer extends React.Component {
  componentDidMount() {
    this.player = videojs(
      this.videoNode,
      this.props,
      function onPlayerReady() {}
    );
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div data-vjs-player>
        { /* eslint-disable-next-line jsx-a11y/media-has-caption */ }
        <video
          ref={(node) => (this.videoNode = node)}
          className={cx("video-js", "vjs-theme-sea")}
        />
      </div>
    );
  }
}

export default VideoPlayer;
