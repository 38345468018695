// extracted by mini-css-extract-plugin
export var footer__contentBlock = "footer-module--footer__content-block--pt1lp";
export var footer__contentWrapper = "footer-module--footer__content-wrapper--k-Ulx";
export var footer__icon = "footer-module--footer__icon--YhzRT";
export var footer__iconLink = "footer-module--footer__icon-link--Fbipz";
export var footer__iconsWrapper = "footer-module--footer__icons-wrapper--voxLi";
export var footer__link = "footer-module--footer__link--f55jM";
export var footer__menu = "footer-module--footer__menu--OTYn0";
export var footer__menuItem = "footer-module--footer__menu-item--ED0g4";
export var footer__section = "footer-module--footer__section--cm6z+";
export var footer__sectionSecondary = "footer-module--footer__section--secondary--Ig4q+";
export var footer__title = "footer-module--footer__title--758P4";