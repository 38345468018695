import React from "react";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge"
import moment from "moment-timezone";
import "moment/min/locales";

import { isMeetingDateValid } from "../services/meetingDateValidation";
import * as styles from "./hero-section.module.scss";
import ScheduleMeetingButton from "../schedule/ScheduleMeeting";

const HeroSection = ({ defaultHeroBannerData, companyData, id }) => {
  const { heroBanner, heroBannerText, ctaButtonText } = defaultHeroBannerData;

  const {
    meetingDateFrom,
    meetingDateTo,
    meetingTopic,
    node_locale,
  } = companyData;

  const banner = companyData.heroBanner
    ? companyData.heroBanner.gatsbyImageData
    : heroBanner.gatsbyImageData;
  const bgImage = convertToBgImage(banner)

  const getMeetingDateTimeString = () => {
    moment.locale(node_locale);
    const userTimezone = moment.tz.guess();
    const startDatetime = moment.utc(meetingDateFrom).tz(userTimezone);
    const endDatetime = moment.utc(meetingDateTo).tz(userTimezone);

    if (startDatetime.format("LL") === endDatetime.format("LL")) {
      return `${startDatetime.format("LL")}, ${startDatetime.format(
        "LT"
      )} \u2013 ${endDatetime.format("LT")}`;
    }

    if (startDatetime.format("LL") !== endDatetime.format("LL")) {
      return `${startDatetime.format("LLL")} \u2013 ${endDatetime.format(
        "LLL"
      )}`;
    }
  };

  const isMeetingInformationVisible = isMeetingDateValid(
    meetingDateFrom,
    meetingDateTo
  );

  return (
    <section className={styles["heroSection"]}>
      <BackgroundImage
        Tag="div"
        className={styles["heroSection__background"]}
        style={{ position: "absolute" }}
        {...bgImage}
        backgroundColor={`#040e18`}
        id={id}
      />
      <div className={styles["heroSection__contentWrapper"]}>
        <div className={styles["heroSection__content"]}>
          <h1 className={styles["heroSection__title"]}>
            {heroBannerText.heroBannerText}
          </h1>
          {isMeetingInformationVisible && (
            <>
              <div className={styles["heroSection__meetingInformation"]}>
                {meetingTopic} <br />
                {getMeetingDateTimeString()}
              </div>
              <div className={styles["heroSection__action"]}>
                <ScheduleMeetingButton
                  ctaText={ctaButtonText}
                  companyData={companyData}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

export const query = graphql`
  fragment HeroBanner on ContentfulCorpCompany {
    heroBanner {
      title
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  fragment DefaultHeroBanner on ContentfulCorpSeoMetadataHeaderAndFooter {
    heroBannerText {
      heroBannerText
    }
    heroBanner {
      title
      gatsbyImageData(layout: FULL_WIDTH)
    }
    ctaButtonText
  }
`;
