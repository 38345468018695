import React from "react";
import cx from "classnames";
import * as styles from "./person-card.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaLinkedin, FaUser } from "react-icons/fa";

const PersonCard = ({ personData, className }) => {
  return (
    <div
      className={cx(className, {
        [styles["personDetailsHasSocialNetwork"]]: personData.linkedIn,
      })}
    >
      {personData.headshot && (
        <GatsbyImage
          image={personData.headshot.gatsbyImageData}
          alt={personData.headshot.title} />
      )}
      {!personData.headshot && (
        <div className={styles["personDetails__placeholder"]}>
          <FaUser className={styles["personDetails__placeholderIcon"]} />
        </div>
      )}
      <div className={styles["personDetails__content"]}>
        {personData.name && (
          <div className={styles["personDetails__name"]}>
            {personData.name}
          </div>
        )}
        {personData.position && (
          <div>
            {personData.position}
          </div>
        )}
        {personData.phone && (
          <a
            className={styles["personDetails__phone"]}
            href={`tel:${personData.phone}`}
          >
            {personData.phone}
          </a>
        )}
        {personData.email && (
          <a
            className={styles["personDetails__email"]}
            href={`mailto:${personData.email}`}
          >
            {personData.email}
          </a>
        )}
        {personData.linkedIn && (
          <a
            href={personData.linkedIn}
            rel="noopener noreferrer"
            target="_blank"
            className={styles["personDetails__socialNetwork"]}
          >
            <FaLinkedin />
          </a>
        )}
      </div>
    </div>
  );
};

export default PersonCard;
